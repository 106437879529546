/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeIMS } from "@identity/imslib";
import { IEnvironment } from "@identity/imslib/adobe-id/IEnvironment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAvatar } from "../hooks/useAvatar";

import type { LoggerType } from "../types/logger";
import type { ITokenInformation } from "@identity/imslib/adobe-id/custom-types/CustomTypes";
import type { IAdobeIdData } from "@identity/imslib/adobe-id/IAdobeIdData";
import type { WithOptional } from "@shared/types";

export type AuthState = "verifying" | "authenticated" | "authorized" | "failed";

const tokenRefreshExpireThreshold = 60 * 60 * 1000; // expires in 1 hour

type Role = { named_roll: string };

export type IMSEnvironment = IEnvironment;

export type PartialImsConfig = WithOptional<
    IAdobeIdData,
    | "environment"
    | "onReady"
    | "onAccessTokenHasExpired"
    | "onError"
    | "onAccessToken"
    | "onReauthAccessToken"
>;

interface UserInfo {
    userId?: string;
    orgId?: string;
    userToken?: string;
    authState: AuthState;
}

export type UserContextValue = {
    imsReady: boolean;
    authState: AuthState;
    avatarUrl: string;
    accessToken?: string;
    userId?: string;
    userProfile?: {
        account_type: string;
        displayName: string;
        first_name: string;
        last_name: string;
        ownerOrg: string;
        userId: string;
        roles: Role[];
        countryCode: string;
        email: string;
        serviceAccounts: { serviceCode: string; serviceLevel?: string }[];
    };
    getUserInfo: () => Promise<UserInfo>;
    getAuthHeaders: () => Promise<{ [key: string]: string }>;
    isAdmin: () => boolean;
    isDev: () => boolean;
    logIn: () => void;
    logOut: () => void;
    authInvalid: () => Promise<void>;
};

export type UserProfile = UserContextValue["userProfile"];

export const UserContext = createContext<UserContextValue>(
    {} as UserContextValue,
);

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context.getAuthHeaders) {
        throw new Error("useUserContext must be used within a UserProvider.");
    }
    return context;
}

// Singleton
let ims: AdobeIMS;

function hasRole(roleName: string, roles: Role[] = []) {
    return !!roles.find((role) => role.named_roll === roleName);
}

type Props = {
    blockContentUntilAuthed?: boolean;
    hydratedAuthToken?: string;
    hydratedUserId?: string;
    isProd: boolean;
    imsConfig: PartialImsConfig;
    autoLogin?: boolean,
    onImsInstance?: (imsInstance: AdobeIMS) => void;
    onLogin?: () => void;
    onLogout?: () => void;

    onAuthorized?: () => void;
    onUnauthorized?: () => void;
    hasAPIAccess?: (token: string) => Promise<boolean>;

    logger?: LoggerType;
    loginRequiredPaths?: string[];
    isLoginRequired?: () => boolean;
};

export const UserProvider = ({
    blockContentUntilAuthed = false,
    hydratedAuthToken = "", // use "" as default value to differentiate between missing auth token state and default state
    hydratedUserId,
    children,
    isProd = false,
    imsConfig,
    autoLogin = true,

    onImsInstance,
    onLogin,
    onLogout,

    onAuthorized,
    onUnauthorized,
    hasAPIAccess = async () => true,

    logger = console,
    loginRequiredPaths,
    isLoginRequired = () => true,
}: React.PropsWithChildren<Props>) => {
    const location = useLocation();
    const [accessToken, setAccessToken] = useState<string | undefined>(
        hydratedAuthToken,
    );
    const [userId, setUserId] = useState<string | undefined>(hydratedUserId);
    const [tokenExpiry, setTokenExpiry] = useState<Date | undefined>();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [authState, setAuthState] = useState<AuthState>("verifying");
    const [imsReady, setImsReady] = useState(false);

    const initializeUser = useRef<(userInfo: UserInfo) => void>();

    if (loginRequiredPaths) {
        isLoginRequired = () => loginRequiredPaths.some((reqPath) => location.pathname.endsWith(reqPath));
    }

    const getUserInfo: () => Promise<UserInfo> = async () => {
        if (authState !== "verifying") {
            return {
                userId,
                orgId: userProfile?.ownerOrg,
                userToken: accessToken,
                authState,
            };
        }
        return new Promise<UserInfo>((res, rej) => {
            initializeUser.current = ({
                userToken,
                orgId,
                userId,
                authState,
            }) => {
                if (userId && userToken) {
                    res({
                        userId,
                        orgId,
                        userToken,
                        authState,
                    });
                } else {
                    rej();
                }
            };
        });
    };

    useEffect(() => {
        if (initializeUser.current && authState !== "verifying") {
            initializeUser.current({
                userId,
                orgId: userProfile?.ownerOrg,
                userToken: accessToken,
                authState,
            });
        }
    }, [
        accessToken,
        userId,
        userProfile?.ownerOrg,
        authState,
        initializeUser.current,
    ]);

    const { avatarUrl, getUserAvatar } = useAvatar(
        imsConfig.client_id,
        imsConfig.environment === IEnvironment.PROD,
        logger,
    );

    useEffect(() => {
        if (!accessToken || !userId) return;
        getUserAvatar(userId, accessToken);
    }, [userId, accessToken]);

    useEffect(() => {
        const updateAccess = () => {
            if (accessToken && hasAPIAccess) {
                hasAPIAccess(accessToken).then((allowed) => {
                    if (allowed) {
                        logger && logger.info("userAuthorised");
                        setAuthState("authorized");
                        onAuthorized && onAuthorized();
                    } else {
                        logger && logger.error("userNotInAllowList");
                        setAuthState("failed");
                        onUnauthorized && onUnauthorized();
                    }
                });
            }
        };
        if (ims && accessToken) {
            ims.getProfile()
                .then((profile: UserProfile) => {
                    setUserProfile(profile);
                    setAuthState("authenticated");
                    setUserId(profile?.userId);
                    updateAccess();
                })
                .catch((err) => {
                    console.error("ims.getProfile fail", err);
                    setAuthState("failed");
                })
                .finally(() => {
                    setImsReady(true);
                });
        }
    }, [ims, accessToken]);

    function invalidateToken() {
        setAccessToken(undefined);
        setTokenExpiry(undefined);
        if (isLoginRequired() && autoLogin) {
            ims.signIn();
        }
    };

    useEffect(() => {
        if (!ims && !accessToken && !userId) {
            ims = new AdobeIMS({
                ...imsConfig,
                environment: isProd ? IEnvironment.PROD : IEnvironment.STAGE,
                onReady: () => {
                    if (ims.isSignedInUser()) {
                        const accessTokenInfo = ims.getAccessToken();
                        setAccessToken(accessTokenInfo?.token);
                        setTokenExpiry(accessTokenInfo?.expire);
                        ims.validateToken().then(isValid => {
                            if (!isValid) {
                                invalidateToken();
                            }
                        });
                    } else if (isLoginRequired()) {
                        if (autoLogin) {
                            ims.signIn();
                        } else {
                            setAuthState("failed");
                        }
                    } else {
                        setAccessToken(undefined);
                    }
                },
                onAccessTokenHasExpired: () => {
                    logger.error("Token Expired");
                    invalidateToken();
                },
                onError: (e) => {
                    logger.error("Error accessing IMS", e);
                    setTimeout(invalidateToken, 5_000);
                },

                // Requiring these nulls isn't great issue logged here:
                // https://git.corp.adobe.com/IMS/imslib2.js/issues/259
                onAccessToken: null,
                onReauthAccessToken: null,
            });

            onImsInstance && onImsInstance(ims);
            // @ts-ignore For developer convenience
            window.adobeIMS = ims;

            ims.enableLogging();

            ims.initialize();
        }
    }, [accessToken, userId]);

    const logIn = () => {
        if (ims) {
            logger.info("userSignIn");
            onLogin && onLogin();
            ims.signIn();
        }
    };

    const logOut = () => {
        if (ims) {
            logger.info("userSignOut");
            setAccessToken(undefined);
            setTokenExpiry(undefined);
            onLogout && onLogout();
            ims.signOut();
        }
    };

    const checkTokenExpiry = async () => {
        if (
            ims &&
            accessToken &&
            tokenExpiry &&
            tokenExpiry.getTime() - tokenRefreshExpireThreshold < Date.now()
        ) {
            logger.warn("Token expired, updating token");
            try {
                const tokenInfo: ITokenInformation | null =
                    await ims.refreshToken();
                if (tokenInfo) {
                    setTokenExpiry(tokenInfo.expire);
                    setAccessToken(tokenInfo.token);
                    return {
                        accessToken: tokenInfo.token,
                        tokenExpiry: tokenInfo.expire,
                    };
                } else {
                    ims.reAuthenticate();
                }
            } catch (e) {
                ims.reAuthenticate();
            }
        }
        return;
    };

    const authInvalid = async () => {
        if (ims) {
            setAccessToken(undefined);
            setUserProfile(undefined);
            setUserId(undefined);
            setTokenExpiry(undefined);
            ims.signOut();
        }
    };

    useEffect(() => {
        window.addEventListener("focus", checkTokenExpiry);

        return () => {
            window.removeEventListener("focus", checkTokenExpiry);
        };
    });

    useEffect(() => {
        checkTokenExpiry();
    }, [location.pathname, location.search]);

    const getAuthHeaders = async () => {
        const { userToken } = await getUserInfo();
        const updatedToken = await checkTokenExpiry();

        return {
            "Content-Type": "application/json",
            authorization: `Bearer ${updatedToken?.accessToken || userToken}`,
            "x-api-key": imsConfig.client_id,
        };
    };

    return (
        <UserContext.Provider
            value={{
                authState,
                accessToken,
                userId,
                userProfile,
                imsReady,
                avatarUrl,
                getUserInfo,
                getAuthHeaders,
                authInvalid,
                logIn,
                logOut,
                isAdmin: () =>
                    hasRole("LICENSE_DEV_ADMIN", userProfile?.roles as Role[]),
                isDev: () => hasRole("org_admin", userProfile?.roles as Role[]),
            }}>
            {blockContentUntilAuthed && authState !== "authorized"
                ? undefined
                : children}
        </UserContext.Provider>
    );
};
