/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { startAdobeViewer, stopAdobeViewer } from "@3di/adobe-3d-viewer";
import { DEFAULT_IBL_URL } from "@shared/common";
import { useEffect, useRef, useState } from "react";

import { SceneManager } from "../scene/SceneManager";

import type { OptimizerSettings } from "../Studio";
import type { RenderSettings } from "@shared/types";
import type { CameraOverride } from "@shared/types/src/studio";

type AdobeViewerConfiguration = NonNullable<
    Parameters<typeof startAdobeViewer>[4]
>;

const sunriseViewerConfig: AdobeViewerConfiguration = {
    extends: "minimal",
    engine: {
        antialiasing: true,
        engineOptions: {
            preserveDrawingBuffer: true,
            adaptToDeviceRatio: true,
            antialias: true,
        },
    },
    autoCreateSunlight: false,
    autoCreateIBL: false,
    autoStopRendering: true,
    bloomEnabled: false,
    maxRenderingGroups: 2,
    useRangeRequests: false,
    renderCameraThumbnails: false, // suppress rendering of camera thumbnails used for UI
    ssaoEnabled: true, // If the scene optimizer is started, it may turn this off
    disableUI: true, // disable default nav bar UI

    // false should now look very similar to true
    // but the pivot point will be on the central axis of the model
    pickSmartCameraOrbitPoint: false, // false to use center of model's bounding box

    // These seem to only get used if autoCreateIBL is set to true.
    // So, I think we can leave these as empty strings.
    assetsBaseUrl: "",
    defaultEnvironmentPath: "",
};

export function use3dSceneManager(
    ref: React.RefObject<HTMLDivElement>,
    enableLimitedZoom = false,
    cameraName?: string,
    renderSettings?: RenderSettings,
    aspectRatio?: number,
    iblUrl?: string,
    customViewerConfig?: AdobeViewerConfiguration,
    optimizerSettings?: OptimizerSettings,
    onModelLoadError?: () => void,
    wideFraming = false,
) {
    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const modelLoading = useRef<boolean>(false);
    const modelLoaded = useRef<boolean>(false);

    const disposeCurrentView = (domRef: HTMLDivElement) => {
        if (domRef) {
            stopAdobeViewer(domRef);
            setSceneManager(undefined);
            modelLoaded.current = false;
        }
    };

    //on window re-focus (tab switch), sometimes the renderer stops without fully rendering the frame
    useEffect(() => {
        const renderToggle = () => {
            if (!document.hidden) sceneManager?.viewer?.renderLoop?.activate();
        };
        window.document.addEventListener("visibilitychange", renderToggle);
        return () => {
            window.document.removeEventListener("visibilitychange", renderToggle);
        }
    }, []);

    useEffect(() => {
        let viewerContainerRef: HTMLDivElement;
        if (ref.current) {
            viewerContainerRef = ref.current;
        }
        return () => {
            disposeCurrentView(viewerContainerRef);
        };
    }, []);

    const load3dScene = async (
        modelUrl: string,
        initialCameraOverride?: CameraOverride,
        onCameraOverrideUpdate?: (cameraOverride: CameraOverride) => void,
        onViewLoaded?: () => void,
    ) => {
        const domRef = ref.current;
        if (domRef && modelUrl && !modelLoading.current) {
            modelLoading.current = true;
            const viewerConfig: AdobeViewerConfiguration = {
                ...(customViewerConfig ?? sunriseViewerConfig),
                iblUrl: iblUrl || DEFAULT_IBL_URL,
                autoCreateIBL: true,
                iblShadowOverrides: {
                    shadowOpacity: 0.3,
                },
            };
            const viewer = await startAdobeViewer(
                domRef,
                modelUrl,
                ".glb",
                undefined,
                viewerConfig as AdobeViewerConfiguration,
                onModelLoadError,
            );
            if (!viewer) {
                throw new Error(`Failed to create viewer for ${modelUrl}`);
            }
            viewer.onModelLoadedObservable.addOnce(() => {
                modelLoading.current = false;
                modelLoaded.current = true;

                const newSceneManager = new SceneManager(viewer!, {
                    cameraOverride: initialCameraOverride,
                    onUpdateCameraPosition: onCameraOverrideUpdate,
                    enableLimitedZoom,
                    cameraName,
                    renderSettings,
                    aspectRatio,
                    optimizerSettings,
                    wideFraming,
                }, modelUrl);
                setSceneManager(newSceneManager);
                if (onViewLoaded) {
                    onViewLoaded();
                }
            });
        }
    };

    return {
        sceneManager,
        load3dScene,
        modelLoaded,
    };
}
